import './Master.css'; //Imports the design

function Header() { //IDK WHY FUNCTION 
	
	return (
		<div className="header" >
			<h1>Hispanic-Latine Student Association</h1>
            
		</div>
	);
}

export default Header;